import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedFeatureResponsiveService {
  isMobile = signal<boolean>(false);

  private mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  constructor() {
    this.mobileQuery = window.matchMedia('(max-width: 767px)');
    this.updateIsMobileSignal();

    this.mobileQueryListener = () => this.updateIsMobileSignal();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
  }

  private updateIsMobileSignal(): void {
    this.isMobile.set(this.mobileQuery.matches);
  }
}
