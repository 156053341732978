import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class SharedFeatureTranslateFacade {
  private readonly translateService = inject(TranslateService);

  translate(key: string, params?: Record<string, unknown>): string {
    return key ? this.translateService.instant(key, params) : '';
  }
}
