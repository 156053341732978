import { Injectable, signal } from '@angular/core';
import { LocalStorage } from '@enx/shared/util/enums';

@Injectable({
  providedIn: 'root',
})
export class SharedFeatureConsentStateService {
  public readonly consentState = signal<boolean>(false);

  constructor() {
    const storedConsent = localStorage.getItem(LocalStorage.CONSENT_STORAGE_KEY);
    if (storedConsent === 'true') {
      this.consentState.set(true);
    }
  }

  public setConsent(granted: boolean): void {
    this.consentState.set(granted);
    localStorage.setItem(LocalStorage.CONSENT_STORAGE_KEY, granted.toString());
  }
}
