import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedFeatureConsentStateService } from './shared-feature-consent-state.service';
import { SharedUiCookieConsentDialogComponent } from '@enx/shared/ui/components';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedFeatureCookieConsentService {
  private readonly consentState = inject(SharedFeatureConsentStateService);
  private readonly dialog = inject(MatDialog);
  private dialogRef: MatDialogRef<SharedUiCookieConsentDialogComponent> | null = null;

  public requestStorageAccess(): Observable<boolean> {
    if (this.consentState.consentState()) {
      return of(true);
    }

    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }

    this.dialogRef = this.dialog.open(SharedUiCookieConsentDialogComponent, {
      hasBackdrop: false,
      position: { bottom: '0' },
      width: '100%',
      maxWidth: '100%',
      panelClass: ['p-0', 'm-0', 'absolute', 'bg-transparent', 'shadow-none'],
      disableClose: true,
      closeOnNavigation: true,
    });

    return this.dialogRef.afterClosed().pipe(
      tap((result) => {
        if (result === true) {
          this.consentState.setConsent(true);
        }
        this.dialogRef = null;
      }),
      map((result) => result === true),
    );
  }
}
